import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youcrm.scss"

import SEO from "../../components/seo"
import HeaderImg from "../../assets/img/youCRM/Img1.png"
import HeaderLogoImg from "../../assets/img/compsol/crm_1.svg"
import Icon1 from "../../assets/img/youCRM/Icon1.png"
import Icon2 from "../../assets/img/youCRM/Icon2.png"
import Icon3 from "../../assets/img/youCRM/Icon3.png"
import Icon4 from "../../assets/img/youCRM/Icon4.png"
import Icon5 from "../../assets/img/youCRM/Icon5.png"
import GroupImg1 from "../../assets/img/youCRM/Img2.png"
import GroupImg2 from "../../assets/img/youCRM/Img3.png"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="youCRM" keywords={[`youpal`, `youpal group`, `youCRM`]} />
      <div className="youCRMMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                You Forget, CRM Doesn't
                </h2>
              </div>
            </div>

            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol mb-4">
                If you have thousands of customers, you probably either don't engage with them or have many employees to keep them organised. With YouCRM, you can scale your business and only need a few colleagues to satisfy thousands of customers' queries actively.
              </p>
            </div>

            <div className="col-12 order-4 order-md-3">
              <p>
                Integration options help gather data from all your sources. Contact management segments them into categories making usage of data easier. Lead scoring and prioritization automate moving potential customers across your sales funnel.
              </p>
            </div>

            <div className="col-12 order-5 order-md-4">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-3 mt-md-5 productButton d-block mx-auto">
                <span>Free demo</span>
              </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-5">
              <Link to="/products/request-demo">
              <img className="headerImg" src={HeaderImg} />
              </Link>
            </div>
            
          </div>
        </section>

        <section className="container solutionsSection">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            All-in-one database
            </h3>
            <p className="internalNarrowCol mb-4">
              Oh, that feeling you live every time you pull a drawer and try to find a person's contact information among hundreds of business cards. Or if you're a more digital-savvy person, you'd always be trying to find creative ways to organise all your data in Excel.
            </p>
            <p className="internalNarrowCol">
              On the other hand, you can integrate YouCRM with all of your lead acquisition sources and customer relationship software. It'll track all touchpoints of your leads and customers separately. And you'll be able to keep and manage customer support tickets at the same place.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Miss no detail</h4>
              <p>
              Our CRM solution will collect information that you didn't know it existed. Improving your relationship with the customers depends on how much you know about their interests, choices, lifestyle, and buying habits.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Design your CRM</h4>
              <p>
              We offer you an opportunity to modify and customise your CRM according to your needs through our highly customisable and flexible solutions.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Integrate anything</h4>
              <p>
              No matter how perfect a product is, there's always something to be added. Use our REST API to integrate your CRM with the other tools and software you use. 
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection analyticsSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                ANALYTICS
                </div>
                <h3 className="text-left">Customer service that creates loyal customers</h3>
                <p>
                Your challenges in providing satisfying customer service grow at the same rate as your business. Reaching huge audiences using various channels is not enough. You should also be able to provide those people with such excellent customer service that they'd gladly be your brand ambassadors.
</p>
                <p>
                  
Comprehensive integration solutions of YouCRM gather all your communication channels under one umbrella. When a customer reaches out to you, your customer representatives will instantly see all your communication history with that customer. Even if they're done through various channels such as Facebook, Instagram, email, etc.
</p>
                <div className="buttonArea">
                <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="row mt-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon4} />
                    </div>
                    <div className="col sideContent">
                        <h4>Close more deals</h4>
                        <p>
                        Knowing your customers' preferences will let you build a more personalized journey for them. YouCRM features such as advanced analytics and detailed reporting make accurate forecasting possible.
</p>
                        <p>
                        
Increasing sales with the same time and energy invested is an eventual outcome of YouCRM. The core principle here is to focus on the leads that are most likely to convert. Thanks to our scoring features, this can be achieved easily.
</p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon5} />
                    </div>
                    <div className="col sideContent">
                    <h4>Trust data, not instincts</h4>
                        <p>
                        YouCRM advanced analytics tools are your best friends in making smarter business decisions. Managing and improving your sales performance starts with measuring first.
</p>
                        <p>
                        
Set your key performance indicators for the next quarter and see how close you're in reaching those targets. Let the data tell you which message you should send to whom and when.
</p>
                        <p>
                        
As YouCRM integrates with all your channels, it can also show you which platforms your customers specifically prefer as means of communication with your brand.
</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection customersSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">Customers</div>
                <h3>Earn more with satisfied customers</h3>
                <p className="mt-4 mt-md-5">
                YouCRM is all about addressing thousands of customers or leads and still making sure each one of them feels special.
</p>
                <p>
                  
It makes your sales representatives more knowledgeable about your customers' behaviors. Moreover, onboarding new customer service and sales representatives are less time-consuming and thus cheaper with YouCRM.
</p>
                <p>
                 
Each day you're not using CRM costs you a great deal of precious data that could lead to more revenue.
                </p>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
